<template>
	<!-- eslint-disable max-len -->
	<BaseDrawer @close="$emit('close')" :allow-close="!isSendingRefill" class="operator-RefillDrawer z-40">
		<header class="operator-RefillDrawer_Header text-center mb-4">
			<h1 v-t="'operator.refill'" class="mb-1"></h1>
			<span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"
				>{{ $t('operator.shelf') }} {{ refillSlotData.shelve.position + 1 }} {{ $t('operator.slot') }} {{ Number(refillSlotData.position + 1) }}</span
			>
		</header>

		<!-- Current product -->
		<div class="bg-day-04dp dark:bg-night-06dp p-3 rounded-2xl grid gap-2">
			<div class="operator-RefillDrawer_Product flex items-center bg-day-04dp dark:bg-night-16dp p-2 rounded-lg">
				<div class="bg-white rounded py-4 w-20 h-20">
					<img v-if="refillSlotData.product.image" :src="refillSlotData.product.image" class="w-full h-full object-contain" />
				</div>

				<div class="ml-2">
					<span class="block text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('operator.current-product') }}</span>
					<span class="block font-medium text-base leading-5">{{ refillSlotData.product.name }}</span>
				</div>
			</div>

			<div class="grid row-gap-2">
				<div class="bg-day-04dp dark:bg-night-16dp p-4 rounded-xl flex items-center justify-between">
					<span v-t="'operator.current-stock'"></span>

					<AppAmountSelector
						:amount="initialStock"
						:max="refillSlotData.stock"
						@increase="initialStock = initialStock + 1"
						@decrease="initialStock = initialStock - 1" class="flex-shrink-0"
					/>
				</div>

				<div class="bg-day-04dp dark:bg-night-16dp p-4 rounded-xl flex flex-col">
					<div class="flex items-center justify-between" :class="{ 'opacity-50': refillSlotData.max_to_fill === 0 }">
						<span v-t="'operator.amount-added'"></span>

						<AppAmountSelector
							:amount="addedStock"
							:max="refillSlotData.max_to_fill - addedStockNext"
							@increase="addedStock = addedStock + 1"
							@decrease="addedStock = addedStock - 1"
							class="flex-shrink-0"
						/>
					</div>

					<div v-if="ownStock ? refillSlotData.max_to_fill === 0 : refillSlotData.stock_on_location === 0" class="bg-progressbar-red px-3 py-2 text-white rounded-lg mt-4 text-center">
						<span v-if="ownStock" v-t="'operator.max-capacity-reached'"></span>
						<span v-else v-t="'operator.no-stock-outside-machine'"></span>
					</div>
				</div>
			</div>
		</div>

		<!-- Next Product -->
		<div v-if="refillSlotData.next_product" class="bg-day-04dp dark:bg-night-06dp p-3 rounded-2xl grid gap-2 mt-4">
			<div class="operator-RefillDrawer_Product flex items-center bg-day-04dp dark:bg-night-16dp p-2 rounded-lg">
				<div class="bg-white rounded py-4 w-20 h-20">
					<img v-if="refillSlotData.next_product.image" :src="refillSlotData.next_product.image" class="w-full h-full object-contain" />
				</div>

				<div class="ml-2">
					<span class="block text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('operator.next-product') }}</span>
					<span class="block font-medium text-base leading-5">{{ refillSlotData.next_product.name }}</span>
				</div>
			</div>

			<div class="grid row-gap-2">
				<div class="bg-day-04dp dark:bg-night-16dp p-4 rounded-xl flex items-center justify-between">
					<span v-t="'operator.current-stock'"></span>

					<AppAmountSelector
						:amount="initalStockNext"
						:max="refillSlotData.next_product_stock"
						@increase="initalStockNext = initalStockNext + 1"
						@decrease="initalStockNext = initalStockNext - 1"
						class="flex-shrink-0"
					/>
				</div>

				<div class="bg-day-04dp dark:bg-night-16dp p-4 rounded-xl flex flex-col">
					<div class="flex items-center justify-between" :class="{ 'opacity-50': refillSlotData.next_max_to_fill === 0 }">
						<span v-t="'operator.amount-added'"></span>

						<AppAmountSelector
							:amount="addedStockNext"
							:max="refillSlotData.next_max_to_fill - addedStock"
							@increase="addedStockNext = addedStockNext + 1"
							@decrease="addedStockNext = addedStockNext - 1"
							class="flex-shrink-0"
						/>
					</div>

					<div v-if="ownStock ? refillSlotData.next_max_to_fill === 0 : refillSlotData.next_stock_reserved === 0" class="bg-progressbar-red px-3 py-2 text-white rounded-lg mt-4 text-center">
						<span v-if="ownStock" v-t="'operator.max-capacity-reached'"></span>
						<span v-else v-t="'operator.no-stock-outside-machine'"></span>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!isSendingRefill" class="operator-OperatingDrawer_Actions grid row-gap-2 mt-4">
			<div v-if="error" v-t="'operator.error'" class="bg-secondary-error text-white rounded-xl px-4 py-3 mb-4"></div>

			<AppButton @click="submitSlotRefill" size="small" icon="arrow-right" icon-position="right" width="full">{{ $t('operator.save') }}</AppButton>
			<AppButton @click="$emit('close')" size="small" type="secondary" width="full">{{ $t('operator.go-back') }}</AppButton>
		</div>
	</BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppAmountSelector from '@/components/Interface/AppAmountSelector.vue';
import { reactive, toRefs } from '@vue/composition-api';
import useMachine from '@/composables/useMachine';

export default {
	name: 'RefillDrawer',

	props: {
		refillSlotData: Object,
		reference: String,
	},

	components: {
		BaseDrawer,
		AppButton,
		AppAmountSelector,
	},

	setup(props, { root, emit }) {
		const { code, machineOperatorData } = useMachine();

		const refillData = reactive({
			isSendingRefill: false,
			initialStock: 0,
			addedStock: 0,
			initalStockNext: 0,
			addedStockNext: 0,
			error: null,
		});

		refillData.initialStock = props.refillSlotData.stock;
		refillData.initalStockNext = props.refillSlotData.next_product_stock;

		const submitSlotRefill = async () => {
			refillData.isSendingRefill = true;

			try {
				const requestUrl = `${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/${props.refillSlotData.slot}/refill`;

				const response = await root.$http.put(requestUrl, {
					refill_identifier: props.reference,
					initial_stock: refillData.initialStock,
					added_stock: refillData.addedStock,
					initial_stock_next: refillData.initalStockNext,
					added_stock_next: refillData.addedStockNext,
				});

				refillData.isSendingRefill = false;

				emit('refilled', response.data.data);
			} catch (error) {
				console.log(error);
				refillData.error = true;
				refillData.isSendingRefill = false;
			}
		};

		return {
			...toRefs(refillData),
			submitSlotRefill,
			ownStock: machineOperatorData.value.own_stock,
		};
	},
};
</script>

<style lang="scss" scoped>
.operator-RefillDrawer {
}
</style>
